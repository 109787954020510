import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/Home.js';
import About from './Pages/About.js';
import Services from './Pages/Services.js';
import Contact from './Pages/Contact.js';
import NavBar from './NavBar.js';
import HondBewaking from "./Pages/Services/HondBewaking";
import Chauffeur from "./Pages/Services/Chauffeur";
import Evenement from "./Pages/Services/Evenement";
import Horeca from "./Pages/Services/Horeca";
import Huismeester from "./Pages/Services/Huismeester";
import MobieleBV from "./Pages/Services/MobieleBV";
import Object from "./Pages/Services/Object";
import Persoon from "./Pages/Services/Persoon";
import Receptie from "./Pages/Services/Receptie";
import Zorg from "./Pages/Services/Zorg";
import Haven from "./Pages/Services/Haven";
import Hotel from "./Pages/Services/Hotel";
import Offerte from "./Pages/Offerte";
import MissieDoel from "./Pages/MissieDoel";
import Footer from "./Footer";
import './App.css'; // Import CSS for styles

function LoadingIndicator({ position }) {
    return (
        <div className="loading-indicator" style={{ top: position.top, left: position.left }}>
            <div className="loading-spinner"></div>
        </div>
    );
}

function App() {
    const [loading, setLoading] = useState(false);
    const [indicatorPosition, setIndicatorPosition] = useState({ top: '50%', left: '50%' });
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, [location.pathname]);

    // Update indicator position based on scroll
    useEffect(() => {
        const updateIndicatorPosition = () => {
            const { scrollTop, clientHeight } = document.documentElement || document.body;
            const top = `${scrollTop + (clientHeight / 2)}px`;
            setIndicatorPosition({ top, left: '50%' });
        };

        window.addEventListener('scroll', updateIndicatorPosition);

        return () => {
            window.removeEventListener('scroll', updateIndicatorPosition);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className={`app-container ${loading ? 'blurred' : ''}`}>
            {loading && <LoadingIndicator position={indicatorPosition} />}
            <NavBar/>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/OverOns" element={<About/>} />
                <Route path="/Diensten" element={<Services/>} />
                <Route path="/Diensten/bewaking-met-honden" element={<HondBewaking />} />
                <Route path="/Diensten/chauffeursdiensten" element={<Chauffeur />} />
                <Route path="/Diensten/evenementsbeveiliging" element={<Evenement />} />
                <Route path="/Diensten/horecabeveiliging" element={<Horeca />} />
                <Route path="/Diensten/huismeester" element={<Huismeester  />} />
                <Route path="/Diensten/mobile-surveillance" element={<MobieleBV  />} />
                <Route path="/Diensten/objectbeveiliging" element={<Object  />} />
                <Route path="/Diensten/persoonsbeveiliging" element={<Persoon  />} />
                <Route path="/Diensten/receptiebeveiliging" element={<Receptie  />} />
                <Route path="/Diensten/zorgbeveiliging" element={<Zorg  />} />
                <Route path="/Diensten/havenbeveiliging" element={<Haven  />} />
                <Route path="/Diensten/hotelbeveiliging" element={<Hotel  />} />
                <Route path="/Offerte" element={<Offerte  />} />
                <Route path="/Contact" element={<Contact/>} />
                <Route path="/OverOns/Missie-Doel" element={<MissieDoel/>} />
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
