import {useEffect} from "react";
import '../About.css'
import OfferteFooter from "../OfferteFooter";

function About() {
    useEffect(() => {
        document.title = "Over Ons | D&S Security";
    });

    return (
        <div>
            <div className="content-container">
                <div className="hero-section-about">
                    <div className="hero-background-about"></div>
                    <div className="hero-text-about">
                        <h1>Over Ons</h1>
                    </div>
                </div>

                <div className='CompanyOverviewSection'>
                    <h2>Ons Bedrijf</h2>
                    <p>D&S Security is een jong en dynamisch bedrijf dat zich toelegt op het leveren van hoogwaardige
                        beveiligingsoplossingen voor zowel particulieren als bedrijven. We streven ernaar om een
                        vertrouwde
                        partner te zijn voor onze klanten, waarbij we nauw met hen samenwerken om oplossingen te vinden
                        die
                        echt aan hun behoeften voldoen.</p>
                    <p>Onze toewijding aan excellentie en klanttevredenheid staat centraal in alles wat we doen. We zijn
                        gepassioneerd door wat we doen en streven ernaar om de normen in de beveiligingsindustrie te
                        verhogen. Met ons ervaren team van professionals zijn we klaar om u te helpen uw
                        beveiligingsdoelstellingen
                        te bereiken.</p>
                </div>
            </div>
            <OfferteFooter/>
        </div>
    );
}

export default About;