import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Leaflet.css'
import customMarker from './Images/pingMarker.png'

const customIcon = L.icon({
    iconUrl: customMarker,
    iconSize: [35, 40],
    iconAnchor: [17, 40],
    popupAnchor: [1, -34]
});

const MyMarker = ({ position, children, ...rest }) => {
    const markerRef = useRef(null);

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.openPopup();
        }
    }, []);

    return (
        <Marker position={position} ref={markerRef} {...rest}>
            {children}
        </Marker>
    );
};

const MapLeaflet = () => {
    const mapStyles = {
        height: '400px',
        position: 'relative', // Ensure relative positioning
        zIndex: 1, // Set a z-index to control stacking order
        padding: '10px',
    };

    const defaultCenter = {
        lat: 52.071750,
        lng: 4.298630
    };

    return (
        <div style={{ margin: '20px auto' }}>
            <MapContainer
                center={defaultCenter}
                zoom={20}
                style={mapStyles}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution={null} // Set attribution to null to remove it
                />
                <MyMarker position={[defaultCenter.lat, defaultCenter.lng]} icon={customIcon}>
                    <Popup>
                        D&S Security
                    </Popup>
                </MyMarker>
            </MapContainer>
        </div>
    );
};

export default MapLeaflet;
